<template>

	<!-- 포인트 컬러 적용을 위해 p-level1~3 클래스 필요-->
	<div class="wrap p-level" :class="'p-level' + level">
		<!-- header -->
		<header class="header-step">
			<div class="head-left">
				<button type="button" class="btn-back" @click="$router.go(-1)"><span class="blind">이전 페이지</span></button>
			</div>
			<div class="head-center">
				<div class="head-tit">
					<span class="page-tit">{{ courseVO.shortTitle }}</span>
				</div>
			</div>
			<div class="head-right"></div>
		</header>
		<!-- container -->
		<div class="container">
			<div class="content">
				<div class="swiper book-list">
					<ul class="swiper-wrapper">

						<!-- 완료된 북에는 done 클래스 -->
						<li class="swiper-slide" :class="{ 'ing': (item.isOpen), 'done': (!item.isOpen) }" v-for="(item, index) in mainList">

							<!-- 열린 교육메인 -->
							<template v-if="item.isOpen" >
								<router-link :to="{ path: '/contentList/', query: { emIdx: item.emIdx, level: level } } ">
									<em class="book-vol">{{ item.shortTitle }}</em>
									<div class="thumb" :style="'background-image: url(' + item.thumbnail + ')'"></div>
									<strong class="book-tit">{{ item.eduTitle }}</strong>
									<dl class="book-topic">
										<dt>topic</dt>
										<dd>{{ item.cateCodeVO.codeName }}</dd>
									</dl>
									<!-- 진행중인 북에는 아래 태그 추가 -->
									<span class="ing-text" v-if="item.isActive">학습중</span>
								</router-link>
							</template>
							<!-- 닫힌 교육 -->
							<template v-else>
								<a href="javascript:;">
									<em class="book-vol">{{ item.shortTitle }}</em>
									<div class="thumb" :style="'background-image: url(' + item.thumbnail + ')'"></div>
									<strong class="book-tit">{{ item.eduTitle }}</strong>
									<dl class="book-topic">
										<dt>topic</dt>
										<dd>{{ item.cateCodeVO.codeName }}</dd>
									</dl>
									<!-- 진행중인 북에는 아래 태그 추가 -->
									<span class="ing-text" v-if="item.isActive">학습중</span>
								</a>
							</template>

						</li>

					</ul>
					<div class="swiper-pagination"></div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import $ from 'jquery'

export default {
	name: "mainList",
	data () {

		const swiper = () => {
			new this.Swiper(".p-level .book-list", {
				slidesPerView: "auto",
				spaceBetween: 20,
				freeMode: true,
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					1024: {
						centeredSlides: true,
					},
				},
				watchSlidesProgress: true
			});
		}

		return {
			swiper,

			cidx: this.$route.query.cidx,
			level: this.$route.query.level,

			courseVO: {},
			mainList: [],
			recentAnswer: null,

		}
	},
	created () {

		this.getMainList();
	},
	mounted () {

		//this.swiper();

	},
	methods: {

		getMainList () {

			const self = this
			this.$eventBus.$emit("startProgress")
			this.axiosCaller.get(this, this.APIs.EDU_COURSE + "/getOpenCourseList", {
				cidx: this.cidx,
				openPrevCourse: false,
				openPrevMain: true,
			}, res => {
				const result = res.data
				if ( result.status === "ok" ) {
					if ( result.list.length > 0 ) {
						self.courseVO = result.list[0]
						self.mainList = this.courseVO.eduMainList
						console.log( self.mainList )
						/*for (let i = this.mainList.length - 1; i >= 0; i--) {
							if ( this.mainList[i].isOpen ) {
								this.mainList[i].isActive = true;
								break;
							}
						}*/

						this.getLastStep();

						this.$nextTick(() => {
							this.swiper()
							this.$eventBus.$emit("doneProgress")
						})
					}
				}
			})

		},


		/**
		 * 사용자의 최근 답변 데이터 조회
		 */
		getLastStep() {

			const self = this
			this.axiosCaller.get(this, this.APIs.EDU_MINE + "/getLastStep", {
				userId: this.getLoginId(),
				cateCode: "200101",
			}, res => {
				const result = res.data
				if (result.status === "ok") {

					self.recentAnswer = {
						answerList: result.answerList,
						eduCourseVO: result.eduCourseVO,
						// eduMainVO: result.eduMainVO,
						// contentList: result.eduMainVO.contentList,
					}

					if ( result.eduMainVO != null ) {
						self.recentAnswer.eduMainVO = result.eduMainVO;
						self.recentAnswer.contentList = result.eduMainVO.contentList;

						this.mainList.forEach(value => {
							if ( value.emIdx === result.eduMainVO.emIdx ) {
								value.isActive = true
							}
						})
					}
				}
			})
		},



		goContent( _emIdx, _level ) {

			this.appOpen({
				url: '/contentList/?emIdx=' + _emIdx + "&level=" + _level,
			})

		},


	},
}
</script>

<style scoped>

</style>